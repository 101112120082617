var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list" },
    [
      _c("yqg-header", { on: { "show-contract-us": _vm.showContractUs } }),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c(
          "ul",
          { staticClass: "article-list" },
          _vm._l(_vm.$options.ArticleList, function (article, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "article",
                on: {
                  click: function ($event) {
                    return _vm.jumpToDetail(index)
                  },
                },
              },
              [
                _c("h5", { staticClass: "article-title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(article.title) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "article-content" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(article.content) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0, true),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("yqg-footer", { on: { "show-contract-us": _vm.showContractUs } }),
      _vm._v(" "),
      _c("contract-us", { ref: "contractUs" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article-tip" }, [
      _c("span", { staticClass: "money-tip" }, [_vm._v("付费2元阅读")]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "to-detail", attrs: { href: "javascript:void(0)" } },
        [_vm._v("查看详情>>")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }