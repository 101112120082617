/*
 * @Author: hongweihu
 * @Date: 2021-12-30 16:27:23
 * @Last Modified by: hongweihu
 * @Last Modified time: 2022-06-09 19:22:23
 */

export default [
    {
        title: 'ts保姆级教程，别再说你不会ts了',
        content: `JavaScript 属于动态编程语言，而ts 属于静态编程语言。
            js：边解释边执行，错误只有在运行的时候才能发现
            ts：先编译再执行，在写的时候就会发现错误了（ts不能直接执行，需要先编译成 js ）...`
    },
    {
        title: 'Nginx静态压缩和代码压缩,提高访问速度!',
        content: `随着前端框架的页面，功能开发不断的迭代；安装的依赖，不断的增多；
            这样导致的问题，就是我们的前端框架，会变得十分庞大，打包出来的dist目录会变得很大了喔！！...`
    },
    {
        title: 'springBoot + Redis 实现接口限流',
        content: `Redis 除了做缓存，还能干很多很多事情：分布式锁、限流、处理请求接口幂等性。。。太多太多了
            首先我们创建一个 Spring Boot 工程，引入 Web 和 Redis 依赖，同时考虑到接口限流一般是通过注解来标记，
            而注解是通过 AOP 来解析的，所以我们还需要加上 AOP 的依赖，最终的依赖如下：...`
    },
    {
        title: 'Swift 高阶函数',
        content: `高阶函数在Swift语言中有大量的使用场景，
            本篇分析 Swift 提供的如下几个高阶函数：map、flatMap、compactMap、filter、reduce。...`
    },
    {
        title: 'iOS 动态路由实现方案',
        content: `随着CSDN App技术栈的扩展，从原本最最简单的原生页面到原生页面的跳转，
            扩展到目前同一个App中包含原生页面、H5页面、小程序页面、Flutter页面之间的跳转。...`
    },
    {
        title: 'flutter app抓包姿势',
        content: `flutter app其实就是有一套自己的ssl，所以我们要用firda hook过掉ssl
            1.使用IDA打开libflutter.so，根据网上看到的大佬们的文章显示，我们要搜索一个ssl_client的字符串...`
    },
    {
        title: '入门篇：从 etcd 名字的由来，到安装部署和使用姿势',
        content: `etcd 是一个可靠的分布式 key-value 存储系统，主要用于配置共享和服务注册和发现，具有以下特性：
            简单：基于 gRPC 定义了清晰、面向用户的 API。
            安全：支持可选的客户端 TLS 证书自动认证特性。
            快速：支持每秒 10000 次的写入。
            可靠：基于 Raft 算法协议保证一致性。...`
    },
    {
        title: 'K8s 网关选型初判：Nginx 还是 Envoy',
        content: `本文将从性能和成本、可靠性、安全性 3 方面，对两大开源实现进行比对，
            希望对正在做 K8s 网关选型的企业有所借鉴。 性能和成本 MSE 云原生网关的吞吐性能几乎是 Nginx Ingress Controller 的一倍，
            尤其是传输小文本时性能优势会更明显，如下图所示，网关 CPU 使用率达到 30% 时的吞吐对比...`
    }
];
