/**
 * @author hongweihu
 * @email hongweihu@fintopia.tech
 * @date 2021-12-30 14:28:44.862
 * @desc generated by yqg-cli@5.2.0
 */
import ContractUs from 'moneycan/app/lingyue-tech/component/contact-us';
import YqgFooter from 'moneycan/app/lingyue-tech/component/yqg-footer';
import YqgHeader from 'moneycan/app/lingyue-tech/component/yqg-header';

import ArticleList from '../constant/article-list';

export default {
    name: 'List',

    components: {YqgHeader, YqgFooter, ContractUs},

    head() {
        return {
            meta: [
                {name: 'robots', content: 'noindex,nofollow,noarchive'}
            ]
        };
    },

    title: '瓴岳科技',

    ArticleList,

    methods: {
        jumpToDetail(index) {
            this.$router.replace({
                name: 'paymentInformationDetail',
                params: {index}
            });
        },

        showContractUs() {
            this.$refs.contractUs.changeShow(true);
        }
    }
};
